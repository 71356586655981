import { Box, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import { ERROR_CODE_TO_LANG, hasTranslation } from 'database/config'

interface TableErrorProps {
  error?: unknown | undefined
}

const TAG = 'TableError'

const styles = {
  text: {
    fontWeight: 700,
    fontSize: {
      base: '2rem',
      md: '3rem',
    },
    lineHeight: 1.17,
    maxW: '895px',
    textAlign: 'center',
    px: 6,
  },
} as const satisfies SystemStyleInterpolation

const sx = {
  position: 'absolute',
  background: 'rgba(255, 255, 255, 0.29)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backdropFilter: 'auto',
  backdropBlur: '2px',
} as const

const TableError: FunctionComponent<TableErrorProps> = ({ error }) => {
  const { t } = useTranslation()

  const langKey = hasTranslation(error) ? ERROR_CODE_TO_LANG[error.code] : 'noItemsForFleet'

  return (
    <Box sx={sx} role="alert" aria-live="polite">
      <Text {...styles.text}>{t(`components.table.error.${langKey}.description`)}</Text>
    </Box>
  )
}

TableError.displayName = TAG

export default TableError
